$primary: #ea2f6d;/* MAIN COLOR */
$secondary: #d9f4fc; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


h2,h3 {
    font-family: 'Alfa Slab One', cursive;
}

p {
    font-family: 'Architects Daughter', cursive;
}


@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Architects+Daughter');
// font-family: 'Alfa Slab One', cursive;
// font-family: 'Architects Daughter', cursive;

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: #d9f4fc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .navbar-nav {
        >li>a {
            font-family: 'Architects Daughter', cursive;
            font-size: 16px;
            text-align: center;
            margin-top: 26px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }

            &:hover {
                border-radius: $border-radius;
                background: $primary;
                color: $wht;
                
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

    @media (max-width: 600px){
           margin-top: 15px;
    }

}
.logo {
    max-width: 250px;
    padding: 0.5em;

    @media (max-width: 767px){
          max-width: 175px;
    }
    @media (max-width: 600px){
          max-width: 145px;
    }
}

@media(max-width: 767px) {
    .navbar-header a {
        float: left;
    }
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.tp-bullets.preview4 .bullet, .tp-bullets.preview4 .bullet:hover, .tp-bullets.preview4 .bullet.selected {
    display: none !important;
}


/* .banner {
    background: url(../img/banner.jpg) no-repeat;
    background-size: cover;
} */

.banner-v1 {
    background: url(../img/banner/banner_v1.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v2 {
    background: url(../img/banner/banner_v2.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v3 {
    background: url(../img/banner/banner_v3.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v4 {
    background: url(../img/banner/banner_v4.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v5 {
    background: url(../img/banner/banner_v5.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v6 {
    background: url(../img/banner/banner_v6.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v7 {
    background: url(../img/banner/banner_v7.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v8 {
    background: url(../img/banner/banner_v8.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v9 {
    background: url(../img/banner/banner_v9.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v10 {
    background: url(../img/banner/banner_v10.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.static-banner {
    position: relative;
    width: 100%;
    height: 780px;
    display: table
}

.static-banner .banner-content {
    display: table-cell;
    vertical-align: middle
}

.static-banner .banner-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5)
}

.static-banner .banner-content p {
    font-size: 24px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3)
}

.static-banner-full {
    position: relative;
    width: 100%;
    height: 100vh;
    display: table
}

.static-banner-full .banner-content {
    display: table-cell;
    vertical-align: middle
}

.static-banner-full .banner-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5)
}

.static-banner-full .banner-content p {
    font-size: 24px;
    color: #fff;
    line-height: 31px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3)
}


@media (max-width:480px) {
    .static-banner .banner-content h1, .static-banner-full .banner-content h1 {
        font-size: 48px
    }
}

.btn-banner {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 32px;
    border: 2px solid #fff;
    display: inline-block;
    text-align: center;
    margin-top: 50px;
    background: rgba(0, 0, 0, .05)
}

.btn-banner i {
    color: #fff
}

.btn-banner:hover {
    background-color: rgba(255, 255, 255, .3)
}

.video-banner {
    position: relative;
    background: #000
}

.fullscreen-bg__video {
    width: 100%;
    height: 100%
}

.video-content {
    position: absolute;
    top: 30%;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: table-cell;
    vertical-align: middle
}

.video-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5)
}

.video-content p {
    font-size: 24px;
    color: #fff;
    margin-bottom: 50px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3)
}

@media (max-width:568px) {
    .video-content h1 {
        font-size: 26px
    }
    .video-content p {
        font-size: 16px
    }
    .video-content .btn-banner {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 21px
    }
}

.flexslider .slides>li {
    position: relative
}

.flex-caption {
    bottom: 30px;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    margin: 0;
    padding: 25px 25px 25px 30px;
    position: absolute;
    left: 30px;
    width: 295px
}

.flex-caption h3 {
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 8px;
    text-transform: uppercase
}

.flex-caption p {
    font-size: 14px !important;
    line-height: 22px;
    font-weight: 300;
    color: #fff
}

.color-white {
    color: #fff !important
}

.color-black {
    color: #3a3a3a
}

.btn {
    background-color: $primary;
    // border: 3px solid $primary;
    font-size: 15px;
    padding: 1em 1em;
    color: #fafafa;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition-duration: 0.5s;
   
    &:hover {
    background-color: $wht;
    color: $blk;

        @media (max-width: 1366px){
         background-color: $primary;
          color: $wht;
        }
    }

    &:active, &:focus {
        background-color: $primary;
          color: $wht;
    }
}

.bannercontainer {
    // max-width: 1400px;
    // margin: 0 auto;
    // width: 100%;
    // border: 2px solid #000 !important;
    // border: 8px solid #00F;
    position: relative;
    padding: 0
}

.bannercontainer .banner {
    width: 100%;
    position: relative;
}

.divider{ 
    background: $secondary;
    padding: 20px 0px;

    h1 {
        font-family: 'Alfa Slab One', cursive;
        color:#000;
        padding-bottom: 20px;
    }

    h2 {
        font-family: 'Alfa Slab One', cursive;
        color:#000;
        font-size: 20px;
       @media (max-width: 767px) {
           font-size: 15px;
        }
        @media (max-width: 767px) {
           font-size: 13px;
        }
    }
}

i.fa {
    color:$primary;
    font-size: 100px;
        @media (max-width: 800px) {
           font-size: 75px;
        }
       @media (max-width: 768px) {
           font-size: 60px;
        }
}

.form {
    //margin-bottom: 10px;

    .boxthis {
     width: 100%;
     height :100%;
    }

    h1{
        text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
        font-family: 'Alfa Slab One', cursive;
        color: #ea2f6d;
        padding: 180px 10px;
        font-size: 32px;

        @media (max-width: 1199px) {
                 font-size: 30px;
                 padding: 150px 10px;
            }


            @media (max-width: 892px) {
                 font-size: 25px;
                 padding: 150px 10px;
            }
        
        
            @media (max-width: 767px) {
                padding: 70px 10px;
                font-size: 25px;
            }
    }

    .img{
         background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/img1.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 70% 50%;

        padding: 265px 0px;

        @media (max-width: 767px) {
            background-position: 60% 50%;
            padding: 150px 0px;
        }
    }
}

.form1 {
        h1 {
            text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
            font-family: 'Alfa Slab One', cursive;
            color: #ea2f6d;
            padding: 180px 10px;

            @media (max-width: 1199px) {
                 font-size: 30px;
                 padding: 150px 10px;
            }

            @media (max-width: 892px) {
                 font-size: 25px;
                 padding: 150px 10px;
            }
        
        
            @media (max-width: 767px) {
                padding: 70px 10px;
                font-size: 25px;
            }
        }

        p {
            padding: 20px;
            font-family: 'Architects Daughter', cursive;
            font-size: 18px;
            line-height: 30px;

            @media (max-width: 1199px) {
            font-size: 21px;
            line-height: 22px;
            }

            @media (max-width: 991px) {
            font-size: 18px;
            line-height: 18px;
            } 
            
            @media (max-width: 767px) {
                font-size: 15px;
                line-height: 18px;
            } 
        }

    .img1{
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/img2.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 25% 50%;
        padding: 265px 0px;
    
        @media (max-width: 767px) {
        background-position: 25% 50%;
         padding: 150px 0px;
        }

    }
}

.toBot {

  height: 150px;
  display: flex;                   /* defines flexbox */
  flex-direction: column;          /* top to bottom */
  justify-content: space-between;  /* first item at start, last at end */

}

.outro {
    // background:url(../img/banner3.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/banner3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 25%;
    
    padding: 300px 0px;

    @media (max-width: 767px) {
        padding: 100px 0px;
    }

    h1 {
        text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
        font-family: 'Alfa Slab One', cursive;
        color: #ea2f6d;
    
        @media (max-width: 767px) {
            font-size: 25px;
        }
    }
}

.btn {
font-family: 'Architects Daughter', cursive;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $blk;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}

